$color: #00873c;
.color-green{
	.custum-color{
		color: $color !important;
	}
	.custum-bg{
		background-color: $color !important;
	}
	a.custum-color{
		&:hover{
			color: lighten($color,10%) !important;
		}
	}
	a.custum-bg{
		&:hover{
			background-color: lighten($color,10%) !important;
		}
	}

	.site-picture .swiper-slide span{
		background: rgba(darken($color,8%),.72);
	}
	.site-recruit .list > li i{
		border-color: $color;
		color: $color;
	}
	.site-recruit .list > li .meta li::before{
		background-color: $color;
	}
	#site-footer{
		background-color: $color !important;
		#pageup{
			color: #FFF;
		}
	}
	.recruit-component .tag li{
		border:$color 1px solid;
		background: #FFF;
		color: $color;
	}
	.voice-component .profile dl dd.date{
		border-color: $color;
		color: $color;
	}
	.voice-component .profile dl dd.job{
		background-color: $color;
		border-color: $color;
	}
	.voice-component .faq li dl dd::before{
		border-color: $color;
		color: $color;
	}
	.voice-component .faq li dl dt::before{
		background-color: $color;
	}
	.overlay .table-style.form-style tbody tr th::after{
		background-color: $color;
	}
	.overlay h2{
		i{
			color: $color;
		}
	}
}