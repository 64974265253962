.news-block{
	background: $normal;
    color: #FFF;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 40%;
    font-size: 15px;
    z-index: 2;
    padding: 30px 40px;
    overflow: hidden;
    cursor: pointer;
	@media screen and (max-width:$responsive){
		width: calc(100% - 88px);
		padding: 18px 15px;
		font-size: 12px;
	}
    .timeline{
        line-height: 1;
        width: calc(100% - 64px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
		@media screen and (max-width:$responsive){
			width: calc(100% - 48px);
		}
        time{
            display: inline-block;
            margin: 0 20px 0 0;
            padding: 0 20px 0 0;
            border-right: rgba(#FFF,.24) 1px solid;
        }
    }
    .more{
        position: absolute;
        top: 50%;
        right: 32px;
        font-size: 16px;
        transform: translateY(-50%);
        @include transition(200ms);
		@media screen and (max-width:$responsive){
			right: 15px;	
		}
    }
}

.news-component{
	$pad: 40px;
	$sp-pad: 40px;
    ol{
        li{
            list-style: none;
            @include flex();
            align-items: flex-start;
            margin: 0 0 32px;
            padding: 0 0 32px;
            border-bottom: $border 1px solid;
			@media screen and (max-width:$responsive){
				display: block;
				margin: 0 0 15px;
				padding: 0 0 15px;
			}
            time{
                width: 15%;
                background: $normal;
                color: #FFF;
                padding: 2px 0;;
                text-align: center;
                display: block;
                font-size: 12px;
				@media screen and (max-width:$responsive){
					width: 88px;	
				}
            }
            dl{
                width: 85%;
                padding: 0 0 0 $pad;
				@media screen and (max-width:$responsive){
					padding: 15px 0 0;	
					width: auto;
				}
                dt{
                    font-weight: 700;
                    font-size: 17px;
                    line-height: 1.5;
                    margin: 0 0 8px;
					@media screen and (max-width:$responsive){
						font-size: 15px;	
					}
                }
                dd{
                    font-size: 13px;
                    text-align: justify;
					@media screen and (max-width:$responsive){
						font-size: 12px;	
					}
                }
            }
        }
    }
}