.site-works{
	.listable{
		@include flex();
		@media screen and (max-width:$responsive){
			display: block;	
		}
		li{
			width: 48%;
			margin: 48px 0 0;
			background: #FFF;
			box-shadow: 0 0 32px rgba(#000,.12);
			cursor: pointer;
			@include transition(160ms);
			&:hover{
				transform: scale(1.1);
				box-shadow: 0 0 48px rgba(#000,.16);
			}
			@media screen and (max-width:$responsive){
				margin: 0 0 24px;
				width: auto;
				box-shadow: 0 0 15px rgba(#000,.08);
				&:last-child{
					margin: 0;
				}
			}
			@media screen and (min-width:$responsive + 1px){
				&:nth-child(1),
				&:nth-child(2){
					margin: 0;
				}
			}
			.bg{
				height: 220px;
				background-position: center;
				background-size: cover;
				@media screen and (max-width:$responsive){
					height: 150px;
				}
			}
		}
		h3{
			padding: 16px;
			text-align: center;
			font-size: 16px;
			font-weight: 700;
			@media screen and (max-width:$responsive){
				padding: 10px 15px;
				font-size: 15px;
			}
		}
	}
}

.works-component{
	.profile{
		@include flex();
		align-items: center;
		margin: 0 0 64px;
		@media screen and (max-width:$responsive){
			display: block;
			margin: 6px 0 32px;	
		}
		.bg{
			width: 480px;
			height: 290px;
			background-position: center;
			background-size: cover;
			@media screen and (max-width:$responsive){
				width: 100%;
				height: 200px;
				margin: 0 0 24px;
			}
		}
		dl{
			width: 480px;
			font-size: 15px;
			@media screen and (max-width:$responsive){
				width: 100%;
				font-size: 13.5px;
			}
			dt{
				font-size: 32px;
				font-weight: 700;
				margin: 0 0 12px;
				@media screen and (max-width:$responsive){
					font-size: 20px;
					text-align: center;
				}
			}
		}
	}
	p.txt{
		border: rgba(#000,.04) 4px solid;
		padding: 32px;
		font-size: 16px;
		border-radius: 6px;
		@media screen and (max-width:$responsive){
			font-size: 13.5px;
			padding: 15px;
		}
	}
}